/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useContext } from 'react'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

import 'react-toastify/dist/ReactToastify.css'
import '../../css/lanza.css'
import './styles.scss'

import { Context } from '../../Context'
import Header from '../Header'
import { getLoggedUser } from '../../services/api/get-logged-user'
import { isLoggedIn } from '../Auth'
import { ConfirmModal } from '../ConfirmModal'
import { LoginForm } from '../LoginForm'

toast.configure()

const Layout = ({ children, withoutHeader, className, bgSuffix }) => {
  const {
    isAuth,
    loginTime,
    setLocalLoginTime,
    minSecondsBeforeLogout,
    waitSecondsBeforeLogout
  } = useContext(Context)

  const [image, setImage] = useState('fondo-1')

  useEffect(() => {
    const storaged = window.localStorage.getItem('ldv-background')

    if (!storaged) {
      // si no está logueado
      if (!isLoggedIn()) {
        return
      }

      getLoggedUser()
        .then(res => {
          const background = res.data[0].background_image
          if (!background || background === '') {
            return
          }

          window.localStorage.setItem('ldv-background', background)
          setImage(background)
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      setImage(storaged)
    }
  }, [])

  // sufijo del fondo: 1=imagen completa; 2=textura
  let suffix = 1
  if (!bgSuffix) {
    suffix = 2
  }
  let classNames = `layout-container ${image}-${suffix}`

  if (className) {
    classNames = `${classNames} ${className}`
  }

  let showLoginForm = false
  if (typeof window !== 'undefined') {
    const pathname = window.location.pathname
    if (!isAuth && !['/', '/password-reset/', '/register/'].includes(pathname)) {
      showLoginForm = true
    }
  }

  if (showLoginForm) {
    return <LoginForm />
  }

  const resetLocalTime = () => setLocalLoginTime(0)

  return (
    <div className={classNames}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>LDV</title>
      </Helmet>

      {withoutHeader ? null : <Header />}

      {children}

      {isAuth && (
        <ConfirmModal
          open={loginTime > minSecondsBeforeLogout}
          onClose={resetLocalTime}
          onYes={resetLocalTime}
          content={`No hemos detectado actividad tuya en las últimas dos horas, tendrás que volver a hacer login dentro de ${minSecondsBeforeLogout + waitSecondsBeforeLogout - loginTime} segundos.`}
          yesText='No te cierres, ¡aún estoy aquí!'
        />
      )}
    </div>
  )
}

export default Layout
