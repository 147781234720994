import React from 'react'
import Modal from 'react-modal'

import closeIcon from '../../images/close.svg'

export function ConfirmModal ({ open, onClose, onYes, content, withNo, yesText }) {
  Modal.setAppElement('body')

  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { zIndex: 999 },
        content: {
          width: '700px',
          margin: '0 auto',
          padding: '4rem 4rem 2rem 4rem',
          bottom: 'auto'
        }
      }}
    >
      <div
        onClick={() => onClose()}
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '24px',
          backgroundImage: `url("${closeIcon}")`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '2rem',
          right: '2rem'
        }}
      />

      <div
        style={{
          margin: '0 auto',
          display: 'flex',
          flexWrap: 'wrap',
          fontSize: '18px'
        }}
      >
        {content}
      </div>

      <div
        style={{
          display: 'flex',
          margin: '2rem auto 0 auto',
          justifyContent: 'space-between'
        }}
      >
        {withNo && (
          <button
            style={{
              cursor: 'pointer',
              color: 'white',
              display: 'block',
              backgroundColor: '#1D1D1B',
              borderColor: '#707070',
              fontSize: '18px',
              lineHeight: 1.8,
              padding: '0 1rem',
              fontWeight: 700
            }}
            onClick={() => onClose()}
          >
            NO
          </button>
        )}

        <button
          style={{
            cursor: 'pointer',
            color: 'white',
            display: 'block',
            backgroundColor: 'green',
            borderColor: '#707070',
            fontSize: '18px',
            lineHeight: 1.8,
            padding: '0 1rem',
            fontWeight: 700
          }}
          onClick={() => onYes()}
        >
          {yesText || 'SÍ'}
        </button>
      </div>
    </Modal>
  )
}
