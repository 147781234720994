import React, { useState, useContext } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { postLogin } from '../../services/api/login'
import { Context } from '../../Context'
import { Alert } from '../Alert'
import { Logo } from '../Logo'

export function LoginForm () {
  const { isAuth, activateAuth, setLocalLoginTime } = useContext(Context)
  const [feedback, setFeedback] = useState({ isOpen: false })

  if (isAuth) {
    return null
  }

  function validateAuth () {
    if (typeof window !== 'undefined') {
      const jwt = window.localStorage.getItem('ldv-jwt')
      if (jwt) {
        activateAuth(jwt)
        setLocalLoginTime(0)
      }
    }
  }

  function handleSubmit (values, { setSubmitting }) {
    const body = {
      email: values.email.trim(),
      password: values.password.trim(),
      token2fa: values.token2fa.trim()
    }

    postLogin(body)
      .then(resp => {
        activateAuth(resp.data.token)
        setLocalLoginTime(0)
      })
      .catch(err => {
        let msg = 'No fue posible contactar al servidor'
        if (err.response) {
          msg = err.response.data.message
        }

        setFeedback({
          isOpen: true,
          title: 'Error',
          toggle: () => setFeedback({ isOpen: false }),
          msg
        })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        token2fa: ''
      }}
      validationSchema={Yup.object({
        email: Yup.string().required('Ingresa el email').email('Ingresa un correo válido'),
        password: Yup.string().required('Ingresa la contraseña').min(6, 'Ingresa al menos 6 caracteres'),
        token2fa: Yup.string().required('Token 2FA obligatorio').min(6, 'Ingresa al menos 6 caracteres')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <div className='fixed inset-0 flex items-center justify-center bg-green-900 bg-opacity-80'>
          <Form className='form w-3/4 md:w-1/3'>
            <Logo />

            <h2 className='text-gray-200 uppercase font-bold text-center'>Renovar Autenticación</h2>

            <Alert
              isOpen={feedback.isOpen}
              title={feedback.title}
              msg={feedback.msg}
              color={feedback.color}
              toggle={feedback.toggle}
            />

            <p className='text-gray-200 text-center'><strong>No actualices esta página</strong> para evitar perder información, una vez te autentiques podrás seguir en la página que estabas sin perder datos.</p>

            <div className='form-group'>
              <label htmlFor='email' className='text-gray-200'>Email</label>
              <Field
                name='email'
                autoComplete='off'
                disabled={isSubmitting}
                className='form-control'
              />
              <ErrorMessage
                name='email'
                component='small'
                className='text-red-500'
              />
            </div>

            <div className='form-group'>
              <label htmlFor='password' className='text-gray-200'>Contraseña</label>
              <Field
                type='password'
                name='password'
                autoComplete='off'
                disabled={isSubmitting}
                className='form-control'
              />
              <ErrorMessage
                name='password'
                component='small'
                className='text-red-500'
              />
            </div>

            <div className='form-group'>
              <label htmlFor='token2fa' className='text-gray-200'>Token 2FA</label>
              <Field
                name='token2fa'
                autoComplete='off'
                disabled={isSubmitting}
                className='form-control'
              />
              <ErrorMessage
                name='token2fa'
                component='small'
                className='text-red-500'
              />
            </div>

            <div className='form-group flex justify-between items-end'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='bg-green-900 text-gray-200 px-4 py-1 rounded border border-gray-100'
              >
                {isSubmitting ? 'Renovando...' : 'Renovar'}
              </button>

              <button
                type='button'
                onClick={validateAuth}
                className='text-gray-200 underline'
              >
                  Ya estoy autenticado en otra pestaña
              </button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
