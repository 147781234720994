import axios from 'axios'
import API from '../../components/API'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function postLogin (body) {
  return axios.post(API('login'), body)
}
