import * as React from "react"
import classnames from "classnames"

interface AlertProps {
  title: string
  msg?: string
  color?: string
  isOpen: boolean
  toggle: any
}

export function Alert({
  title = "LDV",
  msg = "Discúlpenos por el inconveniente, por favor intente nuevamente ó comuniquese con soporte.",
  color = "danger",
  isOpen = false,
  toggle
}: AlertProps) {
  const accent = classnames({
    "bg-red-100 border-red-500 text-red-800": color === "danger",
    "bg-teal-100 border-teal-500 text-teal-800": color === "success",
    "bg-yellow-100 border-yellow-500 text-yellow-800": color === "warning",
    "bg-indigo-100 border-indigo-500 text-indigo-800": color === "info",
    "bg-gray-100 border-gray-500 text-gray-800": color === "secondary"
  })

  const textAccent = classnames({
    "text-red-600": color === "danger",
    "text-teal-600": color === "success",
    "text-yellow-600": color === "warning",
    "text-indigo-600": color === "info",
    "text-gray-600": color === "secondary"
  })

  const iconProps = {
    height: "22",
    width: "22",
    className: "fill-current"
  }

  return (
    <>
      {isOpen && (
        <div
          role="alert"
          className={`m-4 border-t-4 rounded-b px-4 py-3 shadow-md relative ${accent}`}
        >
          <div className="flex">
            <div className="py-1 mr-4">
              <span className={`fill-current ${textAccent}`}>
                {(() => {
                  switch (color) {
                    case "success": return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" /></svg>
                    case "warning": return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" /></svg>
                    case "info": return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
                    case "secondary": return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z" /></svg>
                    default: return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" /></svg>
                  }
                })()}
              </span>
            </div>
            <div>
              <p className="font-bold">{title}</p>
              <p className="text-sm">{msg}</p>
            </div>
          </div>
          {toggle && (
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <button onClick={toggle}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" /></svg>
              </button>
            </span>
          )}
        </div>
      )}
    </>
  )
}
